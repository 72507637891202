import './App.css';
import profilePhoto from './assets/images/profile_photo.jpg';

const App = () => {
  return (
    <div className="inset-0 z-30 transition duration-300 lg:absolute">

      <div className="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0">

        <div className="lg:flex lg:justify-between lg:gap-4">

          <header className="lg:sticky lg:top-0 lg:max-h-screen lg:w-1/2 lg:py-24">
            <div className="flex">
              <img src={ profilePhoto } className="rounded-full mt-2 lg:mt-1 mr-5 h-24 w-24 lg:h-32 lg:w-32" />
              <div className="lg:flex-col lg:justify-between">
                <h1 className="text-3xl lg:text-4xl xl:text-5xl font-bold tracking-tight text-slate-800 dark:text-slate-200 sm:text-5xl">
                  Andrew Knight
                </h1>
                <h2 className="mt-1 lg:mt-2 text-lg font-medium tracking-tight text-slate-400 dark:text-slate-200 sm:text-xl">
                  Senior Full-Stack Developer
                </h2>
                <p className="mt-1 md:mt-3 text-sm lg:text-base max-w-xs leading-normal text-slate-800 dark:text-slate-400">
                  I build pixel-perfect, engaging, and accessible digital experiences.
                </p>
              </div>
            </div>

            <ul class="ml-1 mt-8 flex items-center" aria-label="Social media">
              <li class="mr-5 text-xs shrink-0">
                <a class="block text-slate-800 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-200" 
                  href="https://github.com/andrewcknight" 
                  target="_blank" 
                  rel="noreferrer noopener" 
                  aria-label="GitHub (opens in a new tab)" 
                  title="GitHub">
                    <span class="sr-only">GitHub</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="h-6 w-6" aria-hidden="true">
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
                    </svg>
                </a>
              </li>
              <li class="mr-5 text-xs shrink-0"> 
                <a class="block text-slate-800 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-200" 
                  href="https://www.linkedin.com/in/andrewcknight" 
                  target="_blank" 
                  rel="noreferrer noopener" 
                  aria-label="LinkedIn (opens in a new tab)" 
                  title="LinkedIn">
                    <span class="sr-only">LinkedIn</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6" aria-hidden="true">
                      <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                    </svg>
                  </a>
                </li>
                <li class="mr-5 text-xs shrink-0">
                  <a class="block text-slate-800 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-200" 
                    href="https://x.com/andrewcknight" 
                    target="_blank" 
                    rel="noreferrer noopener" 
                    aria-label="Twitter (opens in a new tab)" 
                    title="Twitter">
                      <span class="sr-only">Twitter</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1227" fill="none" class="h-5 w-5" aria-hidden="true">
                      <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="currentColor"></path>
                    </svg>
                  </a>
                </li>
                {/*
                <li class="mr-5 text-xs shrink-0">
                  <a class="block text-slate-800 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-200" 
                    href="https://www.goodreads.com/user/show/143480747-brittany-chiang" 
                    target="_blank" 
                    rel="noreferrer noopener" 
                    aria-label="Goodreads (opens in a new tab)" 
                    title="Goodreads">
                      <span class="sr-only">Goodreads</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" fill="currentColor" class="h-6 w-6" aria-hidden="true">
                      <path d="M663.8 382.4c10.2 74.6-9.4 158-71.8 201.4-44.6 31-105.6 28.2-141.6 11.4-74.2-34.6-99-117.2-93.6-194.4 8.6-121.8 81.8-175.8 150.6-175 93.8-0.4 143.6 63.6 156.4 156.6zM960 176v672c0 61.8-50.2 112-112 112H176c-61.8 0-112-50.2-112-112V176c0-61.8 50.2-112 112-112h672c61.8 0 112 50.2 112 112zM724 626.4s-0.2-68-0.2-434.6h-58v80.6c-1.6 0.6-2.4-1-3.2-2.4-19.2-41.4-71.8-92.6-152-92-103.8 0.8-174.4 62.4-201.2 155.6-8.6 29.8-11.6 60.2-11 91.2 3.4 155.8 90.2 235.6 224.8 230.4 57.8-2.2 109-34 138-90.4 1-2 2.2-3.8 3.4-5.8 0.4 0.2 0.8 0.2 1.2 0.4 0.6 7.6 0.4 61.4 0.2 69-0.4 29.6-4 59-14.4 87-15.6 42-44.6 69.4-89 79-35.6 7.8-71.2 7.6-106.4-2.4-43-12.2-73-38-82.2-83.6-0.6-3.2-2.6-2.6-4.6-2.6h-53.6c1.6 21.2 6.4 40.6 17 58.4 48.4 81 165.4 97 256.4 74.8 99.8-24.6 134.6-109.8 134.8-212.6z" fill=""></path>
                    </svg>
                  </a>
                </li>
                */}
              </ul>
          
          </header>

          <main id="content" className="pt-12 lg:w-1/2 lg:py-24 text-slate-800 dark:text-slate-400 grid gap-y-12 lg:gap-y-24 h-500 overlow-y-auto">
            
            <section id="about" className="grid gap-y-5 text-sm lg:text-base">
              <p>
                Andrew is a senior full-stack developer, spearheading innovative solutions, 
                and steering a dynamic technical team. His expertise lies primarily with 
                Java, Python, React, and Angular where he has lead and contributed to numerous projects. 
                Most notably, Andrew built and continues to grow a medical record system that has been 
                used by a non-profit medical team since 2017, enabling them to provide enhanced care to 
                underserved communities in India.
              </p>
              <p>
                Andrew is also known for cultivating developer talent and growing new developers to produce 
                sophisticated, high-performing software solutions. For the past 10 years, he has been guiding 
                teams to develop and manage significant software projects, ensuring both innovation and 
                excellence in delivery.
              </p>
              <p>
                In an ever-evolving technological landscape, he is passionate about applying his technical 
                expertise and strategic insight in environments that champion creativity, innovation, 
                and impactful solutions.
              </p>
            </section>

            <section id="experience" aria-label="Work experience" className="grid gap-y-6">
              
              <div className="flex p-2 hover:cursor-pointer rounded-md hover:bg-slate-400/10 border border-transparent hover:border-slate-400/50">
                <div className="m-auto mt-1 uppercase text-xs font-semibold">
                  2015 - PRESENT
                </div>
                <div className="m-auto mt-0">
                  <div className="font-semibold slate-900 dark:text-white text-sm lg:text-base">
                    Sr. Programmer / Applications Manager
                  </div>
                  <div className="slate-900 dark:text-white font-light text-sm">
                    Glatfelter Insurance Group (AIG)
                  </div>
                  <div className="mt-2 text-xs lg:text-sm">
                  • Managed a team of application developers, guiding successful project outcomes through a balance of technical and leadership skills
• Enforced quality assurance protocols, ensuring integrity and security for a suite of over 30 enterprise applications
• Assisted in spearheading the full-stack redesign of the Corporate Broker Network application, utilizing Java, Spring, SQL, Angular and other modern front-end technologies for improved functionality and user experience
• Assisted in leading the overhaul of the Producer Center Portal application, focusing on architecture scalability and code maintainability
• Mentored application developers in best practices, enhancing code quality and design pattern usage within the team
                  </div>
                  <div>
                    <ul class="mt-2 flex flex-wrap" aria-label="Technologies used">
                      <li class="mr-1.5 mt-2">
                        <div class="flex items-center rounded-full bg-slate-700 text-white dark:bg-blue-400/20 dark:text-blue-300 px-3 py-1 text-xs font-medium leading-5">
                          Java
                        </div>
                      </li>
                      <li class="mr-1.5 mt-2">
                        <div class="flex items-center rounded-full bg-slate-700 text-white dark:bg-blue-400/20 dark:text-blue-300 px-3 py-1 text-xs font-medium leading-5">
                          Angular
                        </div>
                      </li>
                      <li class="mr-1.5 mt-2">
                        <div class="flex items-center rounded-full bg-slate-700 text-white dark:bg-blue-400/20 dark:text-blue-300 px-3 py-1 text-xs font-medium leading-5">
                          Management
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="flex p-2 hover:cursor-pointer rounded-md hover:bg-slate-400/10 border border-transparent hover:border-slate-400/50">
                <div className="m-auto mt-1 text-xs font-semibold">
                  JAN 2012 - DEC 2014
                </div>
                <div className="m-auto mt-0">
                  <div className="font-semibold slate-900 dark:text-white text-sm lg:text-base">
                    Programmer / Analyst II
                  </div>
                  <div className="slate-900 dark:text-white font-light text-sm">
                    Glatfelter Insurance Group (AIG)
                  </div>
                  <div className="mt-2 text-xs lg:text-sm">
                  • Developed and maintained insurance applications in Java, providing reliable solutions for broker operations
• Managed team projects with an Agile development environment, ensuring on-schedule delivery and compliance with SDLC process
• Utilized Java, Spring, Hibernate, Struts, and JSF to create durable and scalable software
                  </div>
                  <div>
                    <ul class="mt-2 flex flex-wrap" aria-label="Technologies used">
                      <li class="mr-1.5 mt-2">
                        <div class="flex items-center rounded-full bg-slate-700 text-white dark:bg-blue-400/20 dark:text-blue-300 px-3 py-1 text-xs font-medium leading-5">
                          Java
                        </div>
                      </li>
                      <li class="mr-1.5 mt-2">
                        <div class="flex items-center rounded-full bg-slate-700 text-white dark:bg-blue-400/20 dark:text-blue-300 px-3 py-1 text-xs font-medium leading-5">
                          Angular
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="flex p-2 hover:cursor-pointer rounded-md hover:bg-slate-400/10 border border-transparent hover:border-slate-400/50">
                <div className="m-auto mt-1 text-xs font-semibold">
                  APR 2010 - DEC 2011
                </div>
                <div className="m-auto mt-0">
                  <div className="font-semibold slate-900 dark:text-white text-sm lg:text-base">
                    Programmer I
                  </div>
                  <div className="slate-900 dark:text-white font-light text-sm">
                    Glatfelter Insurance Group (AIG)
                  </div>
                  <div className="mt-2 text-xs lg:text-sm">
                  • Built and improved Java web applications, driving the digital evolution of insurance services
• Maintained corporate websites, ensuring reliability and up-to-date content
• Partnered with business units to document workflows, ensuring technical solutions met operational requirements
                  </div>
                  <div>
                    <ul class="mt-2 flex flex-wrap" aria-label="Technologies used">
                      <li class="mr-1.5 mt-2">
                        <div class="flex items-center rounded-full bg-slate-700 text-white dark:bg-blue-400/20 dark:text-blue-300 px-3 py-1 text-xs font-medium leading-5">
                          Java
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </section>

          </main>

        </div>

      </div>

    </div>
  );
}

export default App;
